<template>
	<table class="w-full">
		<thead class="text-left text-sm">
			<tr class="bg-lightblue child:py-4 child:first:pl-4 child:font-semibold">
				<th>Order Number</th>
				<th>Package</th>
				<th>Provider</th>
				<th>Purchased on</th>
				<th>Expires on</th>
			</tr>
		</thead>
		<tbody class="text-dark relative text-sm">
			<tr v-for="subscription in items" :key="subscription.id"
				class="child:pl-4 child:py-3 border-lightblue even:border-b-2">
				<td>{{ subscription.id }}</td>
				<td>{{ subscription.name }}</td>
				<td>{{ subscription.provider }}</td>
				<td>{{ formatDate(subscription.createdAt) }}</td>
				<td>{{ formatDate(subscription.expiresAt) }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script lang="ts" setup>
interface Props {
	active?: boolean
	items?: UserSubscription[]
}

const props = defineProps<Props>()

function formatDate (date: Date) {
	const formattedDate = new Date(date).toLocaleDateString()
	const formattedTime = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

	return `${formattedDate}, ${formattedTime}`
}
</script>

<style lang="sass" scoped>
</style>
