import { notify } from '@kyvg/vue3-notification'
import { t } from 'i18next'

const HTTP_ERRORS = {
	401: t('modals.login.errors.401'),
	429: 'Too many requests, please wait',
	403: 'Insufficient permissions'
}

function sendNotification (message: string | number, type: 'success' | 'warn' | 'info' | 'error') {
	if (typeof message === 'number')
		notify({ text: HTTP_ERRORS[message as keyof typeof HTTP_ERRORS], type })
	else
		notify({ text: message, type })
}

export default {
	error: (msg: string | number = 'Error') => sendNotification(msg, 'error'),
	success: (msg: string | number = 'Error') => sendNotification(msg, 'success'),
	warning: (msg: string | number = 'Error') => sendNotification(msg, 'warn'),
	info: (msg: string | number = 'Error') => sendNotification(msg, 'info')
}
