import config from '@/config'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import * as Sentry from "@sentry/vue";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import './i18n'
import '@vuepic/vue-datepicker/dist/main.css'

import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale
} from 'chart.js'

import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import Notifications from '@kyvg/vue3-notification'
import GoogleLogin from 'vue3-google-login'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)
ChartJS.defaults.font.family = 'Poppins'

import '@/assets/sass/main.sass'

import App from './App.vue'

import router from './router'

const pinia = createPinia()

pinia.use(({ store }) => {
	store.router = markRaw(router)
})

const app = createApp(App)

Sentry.init({
	app,
	dsn: "https://b75900496d32cfaa6c568eb35f54e1a8@o4504796666593280.ingest.us.sentry.io/4507097343066112",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	trackComponents: true,
	hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
	// Performance Monitoring
	tracesSampleRate: 0.1, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/dev-api\.guruvpn\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

AppleID.auth.init({
	clientId: 'com.guruvpn.signin',
	scope: 'email',
	redirectURI: 'https://app.guruvpn.com/account/login',
	usePopup: true
})

app.use(Notifications)
app.use(GoogleLogin, { clientId: config.GOOGLE_AUTH_CLIENT_ID })
app.use(pinia)
app.use(router)
app.use(autoAnimatePlugin)
app.use(I18NextVue, { i18next })

app.mount('#app')
