<template>
  <button :class="getButtonClasses" class="relative flex justify-center items-center" @click.prevent="click">
    <div v-if="isLoading" class="absolute top-1/2 flex items-center justify-center -translate-y-1/2 duration-100 loader">
			<svg :class="[variant === 'outlined' ? `stroke-${theme}` : 'stroke-white']" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" stroke-linecap="round"><animate attributeName="stroke-dasharray" dur="1.5s" calcMode="spline" values="0 150;42 150;42 150;42 150" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/><animate attributeName="stroke-dashoffset" dur="1.5s" calcMode="spline" values="0;-16;-59;-59" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/></circle><animateTransform attributeName="transform" type="rotate" dur="2s" values="0 12 12;360 12 12" repeatCount="indefinite"/></g></svg>
    </div>
    <div :class="{ 'opacity-0 duration-100': isLoading }" class="flex items-center whitespace-nowrap">
      <slot></slot>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import notify from '@/composables/notification'

interface Props {
  size?: 'sm' | 'md' | 'lg'
  variant?: 'solid' | 'text' | 'outlined'
  theme?: 'primary' | 'success' | 'green' | 'warning' | 'danger'
  loading?: boolean
  rounded?: boolean
  disabled?: boolean
	action?: Function
}

interface Emits {
  (e: 'click'): void
}

const emits = defineEmits<Emits>()

const props = withDefaults(defineProps<Props>(), {
	size: () => 'md',
	theme: () => 'primary',
	variant: () => 'solid',
	loading: () => false,
	disabled: () => false
})

const getButtonClasses = computed(() => {
	const rounded = props.rounded ? 'rounded' : ''
	const disabled = props.disabled ? 'disabled' : ''

	return `btn ${props.size} ${props.theme} ${props.variant} ${rounded} ${disabled}`
})

const isLoading = computed(() => {
	return actionLoading.value || props.loading
})

const [actionLoading, setLoading] = useToggle()

async function click () {
	if (!props.loading && !props.disabled) {
		emits('click')

		if (props.action) {
			setLoading(true)

			try {
				await props.action()
			} catch (e) {
				notify.error(e as string)
			} finally {
				setLoading(false)
			}
		}

	}


}
</script>

<style lang="sass" scoped>
.btn
  @apply duration-100 select-none max-w-full leading-full

  &.sm
    @apply px-4 py-4 rounded-md font-medium text-xs
  &.md
    @apply px-4 py-3 rounded-sm font-medium text-base
  &.lg
    @apply px-6 py-4 rounded-md font-medium text-sm

  &.rounded
    @apply rounded-xl

  &.primary
    &.outlined
      @apply border-2 border-primary leading-5 tracking-wide text-primary
    &.solid
      @apply bg-primary tracking-wide text-white

  &.success
    &.outlined
      @apply border border-success leading-5 tracking-wide text-success
    &.solid
      @apply bg-success text-white leading-5 tracking-wide
  &.warning
    &.outlined
      @apply border border-warning leading-5 tracking-wide text-white
    &.solid
      @apply bg-warning leading-5 tracking-wide text-dark
  &.danger
    &.outlined
      @apply border border-danger leading-5 tracking-wide text-danger
    &.solid
      @apply bg-danger text-white leading-5 tracking-wide

  &.green
    &.outlined
      @apply border border-green leading-5 tracking-wide text-green
    &.solid
      @apply bg-green text-white leading-5 tracking-wide
  &.disabled
    @apply opacity-40 cursor-not-allowed duration-150
  &:focus
    @apply outline-none

  &:active
    @apply translate-y-1 duration-75 outline-none
</style>
