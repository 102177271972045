<template>
  <div class="w-full text-dark">
    <BaseCard :title="$t('pages.profile.settings.title')" slim>
      <section class="border-b border-blue-outline px-8 py-4">
        <div class="flex gap-2 items-center">
          <i class="icon-message text-2xl"></i>
          <span class="text-lg font-semibold">{{ $t('pages.profile.settings.email.section') }}</span>
        </div>

        <div class="mt-3">
          {{ $t('pages.profile.settings.email.your') }}
          <span class="font-bold">{{ account.profile.email }}</span>
        </div>

        <div v-auto-animate class="pt-5 pb-4">
          <BaseButton v-if="!isEmailView" rounded size="md" @click="switchEmailView(true), emailForm.email = account.profile.email">{{ $t('pages.profile.settings.email.actions.expand') }}</BaseButton>
          <div v-else>
            <TextField :label="$t('pages.profile.settings.email.labels.email')" v-model="emailForm.email" :errors="validation.email.email" rounded></TextField>
            <TextField type="password" :label="$t('pages.profile.settings.email.labels.password')" v-model="emailForm.password" rounded class="pt-2" :errors="validation.email.password"></TextField>

						<div class="text-grayer text-sm mt-4">
							In order to change your email, you need to enter your current password.
						</div>
            <div class="flex gap-2 pt-6">
							<div v-auto-animate>
								<BaseButton rounded size="md" v-if="hasEmailChanged"
									theme="green"
									@click="clearEmailForm"
								>
									Verification email sent
								</BaseButton>
								<BaseButton rounded size="md" v-else
									:disabled="!isEmailFormValid"
									@click="submitEmailChange"
									:loading="account.loading.update.email"
								>
									{{ $t('pages.profile.settings.email.actions.submit') }}
								</BaseButton>
							</div>
              <BaseButton v-if="!hasEmailChanged" rounded variant="outlined" size="md" @click="switchEmailView(false)">{{ $t('pages.profile.settings.email.actions.cancel') }}</BaseButton>
            </div>
          </div>
        </div>
      </section>

      <!-- CHANGE PASSWORD -->
      <section class="px-8 py-4" v-if="account.profile.hasPassword">
        <div class="flex gap-2 items-center">
          <i class="icon-lock text-2xl"></i>
          <span class="text-lg font-semibold">{{ $t('pages.profile.settings.password.title') }}</span>
        </div>
				<div v-auto-animate>
					<form v-if="!hasPasswordChanged" class="flex flex-col gap-2 mt-4" autocomplete="off" >
						<TextField type="password" :label="$t('pages.profile.settings.password.labels.current')" v-if="account.profile.hasPassword" v-model="passwordForm.current" :errors="validation.password.current" rounded></TextField>
						<TextField type="password" :label="$t('pages.profile.settings.password.labels.new')" v-model="passwordForm.new" :errors="validation.password.new" rounded></TextField>
						<TextField type="password" :label="$t('pages.profile.settings.password.labels.repeat')" v-model="passwordForm.repeat" :errors="validation.password.repeat" rounded></TextField>
					</form>
				</div>
				<BaseButton v-if="hasPasswordChanged" rounded size="md" class="mt-5 mb-4" theme="green">
					Password Changed
				</BaseButton>
        <BaseButton v-else rounded size="md" class="mt-5 mb-4" :disabled="!isPasswordFormValid" @click="submitPasswordChange" :loading="account.loading.update.password">
          {{ $t('pages.profile.settings.password.actions.submit') }}
        </BaseButton>
      </section>
    </BaseCard>
  </div>
</template>

<script lang="ts" setup>
import notify from '@/composables/notification'
import patterns from '../../../composables/validation/patterns'

import { useAccountStore } from '../../../stores/account'
import { z } from 'zod'

const account = useAccountStore()

const [isEmailView, switchEmailView] = useToggle()

const emailForm = reactive({
	email: '',
	password: ''
})

const passwordForm = reactive({
	current: '',
	repeat: '',
	new: ''
})

const isPasswordFormValid = computed(() => {
	return PasswordForm.safeParse(passwordForm).success
})

const PasswordForm = z.object({
	current: z.string().regex(patterns.auth.password, { message: 'Incorrect password' }).optional(),
	new: z.string().regex(patterns.auth.password, { message: 'Incorrect password' }),
	repeat: z.string().regex(patterns.auth.password, { message: 'Incorrect password' })
}).refine(password => password.new !== password.current && account.profile.hasPassword, { message: 'New password must not be same as previous', path: ['new', 'current'] })
	.refine(password => password.new === password.repeat, { message: 'Passwords must match', path: ['repeat'] })

const EmailForm = z.object({
	email: z.string().email({ message: 'Incorrect email' }),
	password: z.string().regex(patterns.auth.password, { message: 'Incorrect password' })
}).refine(form => form.email !== account.profile.email, { message: 'Email must be different' })

const isEmailFormValid = computed(() => EmailForm.safeParse(emailForm).success)

const validation = computed(() => {
	const emailFormValidation = EmailForm.safeParse(emailForm)
	const passwordFormValidation = PasswordForm.safeParse(passwordForm)
	const forms = { email: { valid: true } as any, password: { valid: true } as any }

	if (emailFormValidation.success === false) {
		forms.email = emailFormValidation.error.formErrors.fieldErrors
		forms.email.valid = false
	}

	if (passwordFormValidation.success === false) {
		forms.password = passwordFormValidation.error.formErrors.fieldErrors
		forms.password.valid = false
	}


	return forms
})

const [hasPasswordChanged, setPasswordChanged] = useToggle()
const [hasEmailChanged, setEmailChange] = useToggle()

async function submitPasswordChange () {
	if (!validation.value.password.valid) return

	await account.changePassword(passwordForm.current, passwordForm.new)
	setPasswordChanged(true)
}

async function submitEmailChange () {
	if (!validation.value.email.valid) return

	try {
		account.loading.update.email = true

		await account.changeEmail(emailForm.email, emailForm.password)

		setEmailChange(true)

		notify.success('Verification email has been sent to your new address')
	} catch (e) {
		notify.error(e as string)
	} finally {
		account.loading.update.email = false
	}
}

async function clearEmailForm () {
	setEmailChange(false)
	switchEmailView(true)
	emailForm.password = ''
}

async function requestPasswordReset () {
	try {
		await account.requestPasswordReset()
		notify.success(`Reset instructions were sent to ${account.profile.email}`)
	} catch (e) {
		notify.error(e as string)
	} finally {
		account.loading.update.password = false
	}
}
</script>

<style lang="sass" scoped>
</style>
