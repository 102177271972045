<template>
  <div class="w-full text-dark flex flex-col gap-8">
    <BaseCard class="relative overflow-hidden">
      <div class="text-lg font-semibold">{{ $t('pages.profile.dashboard.app.title') }}</div>
      <div class="py-4 pr-48">{{ $t('pages.profile.dashboard.app.description') }}</div>

      <BaseButton rounded class="mt-2" @click="router.push(config.APPLE_APP_DOWNLOAD_URL)">
				{{ $t('pages.profile.dashboard.app.button.download') }}
			</BaseButton>

      <ConnectionGlobe class="absolute right-0 top-0" />
    </BaseCard>

    <BaseCard :title="$t('pages.profile.dashboard.membership.title')">
      <div class="flex gap-2 items-center">
        <i class="icon-crown text-2xl"></i>
        <span class="text-lg font-semibold">{{ $t('pages.profile.dashboard.membership.title') }}</span>
      </div>
      <div class="py-4" v-if="userHasActiveSubscription">
        {{ $t('common.your') }} <strong>Premium</strong> {{ $t('pages.profile.dashboard.membership.status') }}
				<strong>
					{{ account.getSubscriptionExpirationDate }}
				</strong>
      </div>
			<div class="py-4" v-else-if="account.subscriptions.length > 0">
				Your subscription expired at: {{ account.subscriptions.slice(-1)[0].expiresAt.toDateString() }}
			</div>
			<div class="py-4" v-else>
				<BaseButton theme="warning" class="mt-2" rounded @click="account.router.push('/account/plans')">
					Buy Premium
				</BaseButton>
			</div>

			<BaseButton v-if="userHasActiveSubscription" theme="warning" class="mt-2" rounded @click="account.router.push('/account/plans')">
				<div v-if="userHasActiveSubscription">
					{{ $t('pages.profile.dashboard.membership.button.extend') }}
				</div>
				<div v-else>
					Buy premium
				</div>
			</BaseButton>
		</BaseCard>
  </div>
</template>

<script lang="ts" setup>
import config from '@/config'

import { useRouter } from 'vue-router'

const router = useRouter()

import ConnectionGlobe from '@/components/svg/ConnectionGlobe.vue'
import { useAccountStore } from '@/stores/account'

const account = useAccountStore()

const userHasActiveSubscription = computed(() => {
	return account.getSubscriptionExpirationTime > Date.now()
})


</script>

<style lang="sass" scoped>
</style>
