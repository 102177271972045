import api from '@/composables/api'

import { useAppStore } from '@/stores/app'
import { createRouter, createWebHistory } from 'vue-router'

import DashboardView from '@/views/account/profile/DashboardView.vue'
import SettingsView from '@/views/account/profile/SettingsView.vue'
import ProfileLayout from '@/views/account/profile/ProfileLayout.vue'
import PurchaseHistoryView from '@/views/account/profile/PurchaseHistoryView.vue'
import { useAccountStore } from '@/stores/account'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/test',
			name: 'Playground',
			component: () => import('../views/PlaygroundView.vue')
		},
		{
			path: '/',
			redirect: '/account'
		},
		{
			path: '/profile',
			redirect: '/account/profile'
		},
		{
			path: '/account',
			name: 'Account',
			component: () => import('@/views/account/AccountLayout.vue'),
			redirect: '/account/login',
			children: [{
				path: 'verify',
				name: 'VerifyLogin',
				component: () => import('@/views/account/VerifyAccountPurchaseView.vue')
			},
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/account/LoginView.vue'),
				meta: { onlyUnauthorized: true }
			}, {
				path: 'register',
				name: 'Register',
				component: () => import('@/views/account/RegistrationView.vue'),
				meta: { onlyUnauthorized: true }
			}, {
				path: 'plans',
				name: 'Plans',
				component: () => import('@/views/account/PlansView.vue')

			}, {
				path: 'profile',
				name: 'Profile',
				component: ProfileLayout,
				meta: { protected: true },
				redirect: '/account/profile/dashboard',
				children: [{
					path: 'dashboard',
					name: 'AccountDashboard',
					component: DashboardView,
					meta: { protected: true }
				}, {
					path: 'settings',
					name: 'AccountSettings',
					component: SettingsView,
					meta: { protected: true }
				}, {
					path: 'purchases',
					name: 'AccountPurchases',
					component: PurchaseHistoryView,
					meta: { protected: true }
				}]
			}, {
				path: 'recover/new',
				name: 'NewPassword',
				component: () => import('@/views/account/NewPasswordView.vue'),
				meta: { onlyUnauthorized: true }
			}, {
				path: 'recover',
				name: 'Recover',
				component: () => import('@/views/account/ResetPasswordView.vue'),
				meta: { onlyUnauthorized: true }
			}, {
				path: 'verify-email',
				name: 'VerifyEmailChange',
				component: () => import('@/views/account/VerifyEmailChange.vue')
			}, {
				path: 'activate',
				name: 'VerifyAccount',
				component: () => import('@/views/account/VerifyAccount.vue'),
				meta: { onlyUnauthorized: true }
			}]
		},
		{
			path: '/admin',
			redirect: '/admin/dashboard'
		},
		{
			path: '/admin/login',
			name: 'AdminLogin',
			component: () => import('@/views/admin/AuthroizationView.vue')
		},
		{
			path: '/admin/dashboard',
			name: 'Dashboard',
			component: () => import('@/views/admin/dashboard/DashboardLayout.vue'),
			redirect: '/admin/dashboard/statistics',
			children: [{
				name: 'Statistics',
				path: 'statistics',
				component: () => import('@/views/admin/dashboard/StatisticsView.vue')
			}, {
				name: 'Customers',
				path: 'customers',
				component: () => import('@/views/admin/dashboard/CustomersView.vue')
			}, {
				name: 'Servers',
				path: 'servers',
				component: () => import('@/views/admin/dashboard/ServersView.vue')
			}, {
				name: 'Subscriptions',
				path: 'subscriptions',
				component: () => import('@/views/admin/dashboard/SubscriptionsView.vue')
			}, {
				name: 'Categories',
				path: 'categories',
				component: () => import('@/views/admin/dashboard/CategoriesView.vue')
			}, {
				name: 'Settings',
				path: 'settings',
				component: () => import('@/views/admin/dashboard/SettingsView.vue')
			}, {
				name: 'Posts',
				path: 'posts',
				component: () => import('@/views/admin/dashboard/PostsView.vue')
			}, {
				name: 'Applications',
				path: 'applications',
				component: () => import('@/views/admin/dashboard/ApplicationsView.vue')
			}]
		}
	]
})


router.beforeEach(async (to, from, next) => {
	const app = useAppStore()
	const account = useAccountStore()

	app.loading.view = true

	if (app.domain !== window.location.hostname) {
		await account.logout()
		account.$reset()
		app.domain = window.location.hostname
	}

	if (to.path.startsWith('/admin/dashboard')) {
		if (api.isAuthorized.value && api.isAuthenticated.value) {
			if (!account.profile.role)
				await account.fetchProfile()

			if (account.profile.role === 'ADMIN')
				next(true)
			else
				next('/admin/login')
		} else
			next('/admin/login')
	} else {
		if (to.meta.protected) {
			if (!api.isAuthenticated.value)
				next('/account/login')
			else
				next(true)
		} else if (to.meta.onlyUnauthorized && api.isAuthenticated.value && to.query.action !== 'logout')
			next('/account/profile')
		else
			next(true)
	}
})

router.afterEach(async (to, from, next) => {
	const app = useAppStore()

	if (to.name !== 'VerifyLogin')
		app.loading.state = false

	app.loading.view = false
})

export default router
