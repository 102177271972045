import { defineStore } from 'pinia'

import api from '@/composables/api'
import notify from '@/composables/notification'

export const useAccountStore = defineStore('account', {
	state () {
		return {
			subscriptions: [] as Array<UserSubscription>,
			profileUpdatedAt: useStorage('profile-updated-at', 0),
			profile: useStorage('profile', {
				id: '',
				email: '',
				active: false,
				role: '',
				hasPassword: false,
				subscription: {
					expiresAt: 0
				}
			}),
			auth: {
				tokens: {
					access: useStorage<string>('', ''),
					refresh: useStorage<string>('', '')
				}
			},
			loading: {
				subscriptions: false,
				profile: false,
				update: {
					subscription: false,
					password: false,
					email: false
				}
			}
		}
	},
	getters: {
		isAuthorized: state => state.auth.tokens.access.length > 0 && state.auth.tokens.refresh.length > 0,
		isLoaded: state => state.profile.email.length > 0,
		getSubscriptionExpirationDate: state => {
			const date = new Date(state.profile.subscription.expiresAt)

			return date ? date.toDateString() + ' at ' + date.toLocaleTimeString() + ' UTC' : ''
		},
		getSubscriptionExpirationTime: state => state.profile.subscription.expiresAt
	},
	actions: {
		async logout () {
			await api.logout()

			this.profile = {
				id: '',
				email: '',
				active: false,
				role: '',
				hasPassword: false,
				subscription: {
					expiresAt: 0
				}
			}
		},
		async fetchProfile () {
			const { data } = await api.client.get('/users/profile')
				.finally(() => this.loading.profile = false)

			this.profile.id = data.id
			this.profile.active = data.isActive
			this.profile.email = data.email
			this.profile.hasPassword = data.hasPassword
			this.profile.role = data.role
			this.profile.subscription.expiresAt = new Date(data.subscriptionEndDate).getTime()

			this.profileUpdatedAt = Date.now()
		},
		async cancelPersonalSubscription (uuid: string) {
			this.loading.update.subscription = true

			const { data } = await api.client.patch('/users/subscriptions/cancel', { id: uuid })
				.finally(() => this.loading.update.subscription = false)

			this.subscriptions[this.subscriptions.findIndex(x => x.uuid === uuid)].autorenew = false
		},
		async fetchUserSubscriptions () {
			this.loading.subscriptions = true

			const { data } = await api.client.get('/users/subscriptions/personal')
				.finally(() => this.loading.subscriptions = false)

			this.subscriptions = data.map(s => ({
				...s, uuid: s.id, id: parseInt(s.id.split('-')[0], 16),
				provider: s.providerType === 'Stripe' ? 'Stripe' : 'App Store'
			}))
		},
		async fetchSubscriptionBySessionId (sessionId: string) {
			const { data } = await api.client.get(`/users/subscription/session/${sessionId}`)
			return {
				...data, uuid: data.id, id: parseInt(data.id.split('-')[0], 16)
			}
		},
		async changePassword (oldPassword: string, newPassword: string) {
			this.loading.update.password = true

			try {
				await api.client.post('/auth/change-password', { oldPassword, newPassword })

				notify.success('Password has been successfully changed')
			} finally {
				this.loading.update.password = false
			}
		},
		async requestPasswordReset (targetEmail?: string) {
			this.loading.update.password = true
			console.log(targetEmail)
			const { data } = await api.client.post('/auth/reset-password', { email: targetEmail || this.profile.email })
				.finally(() => this.loading.update.password = false)

			console.log('request reset password')
			console.log(data)
		},
		async verifyPasswordReset (newPassword: string, code: string) {
			this.loading.update.password = true

			const { data } = await api.client.post('/auth/reset-password/confirm', { code, newPassword })
				.finally(() => this.loading.update.password = false)

			console.log('reset password')
			console.log(data)
		},
		async changeEmail (email: string, password: string) {
			this.loading.update.email = true

			const { data } = await api.client.post('/auth/change-email', { newEmail: email, password })
				.finally(() => this.loading.update.email = false)

			console.log(data)
		},
		async verifyEmail (code: string) {
			this.loading.update.email = true

			const { data } = await api.client.post('/auth/change-email/confirm', { code })
				.finally(() => this.loading.update.email = false)

			console.log(data)
		}
	}
})
