import notify from '@/composables/notification'
import api from '@/composables/api'
import i18next from 'i18next'

import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
	state () {
		return {
			domain: useStorage('domain', window.location.hostname),
			settings: {
				language: useStorage<AppLanguage>('lang', 'en')
			},
			header: {
				height: 0
			},
			loading: {
				state: true,
				view: false,
				plans: false,
				payment: {
					link: false
				}
			},
			plansUpdatedAt: useStorage('service-plans-updated-at', 0),
			plans: useStorage('service-plans', [] as Array<ServicePlan>)
		}
	},
	getters: {

	},
	actions: {
		async fetchStripePaymentLink (planId: string, email: string) {
			try {
				if (!planId) throw 'Plan ID is missing'
				if (!email) throw 'Email is missing'

				this.loading.payment.link = true

				const { data } = await api.client.post('/payments/new', { planId, email })

				return data.paymentURL
			} catch {
				notify.error('Unable to proccess payment request')
			} finally {
				this.loading.payment.link = false
			}

			return null
		},
		async fetchPlans () {
			if (!this.plans.length || (Date.now() - this.plansUpdatedAt > 120000)) {
				try {
					this.loading.plans = true

					const { data } = await api.client.get('/payments/plans')

					this.plans = data.plans as Array<ServicePlan>

					this.plans = this.plans.map(plan => ({
						...plan,
						period:
						plan.days === 7
							? 'week'
							: plan.days === 30
								? 'month'
								: plan.days === 365
									? 'year'
									: 'custom'
					}))
					this.plansUpdatedAt = Date.now()

				} catch (e) {
					notify.error(e)
				} finally {
					this.loading.plans = false
				}}
		},
		async changeLanguage (language: AppLanguage) {
			await i18next.changeLanguage(language)

			this.settings.language = language
		}
	}
})
