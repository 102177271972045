<template>
	<div v-auto-animate="{ duration: 200 }">
		<ChangePasswordModal v-if="userNeedsPassword && authorizationCode" />
		<VerifyEmailModal v-else-if="userNeedsEmailVerification && authorizationCode" />
	</div>

  <div class="flex gap-8 w-9/12">
		<div>
			<div class="bg-white text-dark border border-blue-outline rounded-xl h-fit">
				<div class="text-lg px-8 py-5 text-dark font-semibold h-16">{{ $t('pages.profile.sidebar.title') }}</div>

				<nav class="child:text-lg child:px-7 child:py-4 child:h-16 font-medium child:border-t child:border-blue-outline" style="width: 256px;">
					<RouterLink class="flex items-center gap-2" :class="{ 'active': getRoute.endsWith('dashboard') }" to="/account/profile/dashboard">
						<div v-if="getRoute.endsWith('dashboard')" class="bg-primary w-1 right-0 h-full absolute"></div>
						<i class="icon-grid text-2xl font-medium"></i>
						{{ $t('pages.profile.sidebar.dashboard') }}
					</RouterLink>
					<RouterLink class="flex items-center gap-2" :class="{ 'active': getRoute.endsWith('settings') }" to="/account/profile/settings">
						<div v-if="getRoute.endsWith('settings')" class="bg-primary w-1 right-0 h-full absolute"></div>
						<i class="icon-gear text-2xl font-medium"></i>
						{{ $t('pages.profile.sidebar.settings') }}
					</RouterLink>
					<RouterLink class="flex items-center gap-2" :class="{ 'active': getRoute.endsWith('purchases'), 'muted': account.profile.subscription.expiresAt === 0 }" to="/account/profile/purchases">
						<div v-if="getRoute.endsWith('purchases')" class="bg-primary w-1 right-0 h-full absolute"></div>
						<i class="icon-clock-repeat text-2xl font-medium"></i>
						{{ $t('pages.profile.sidebar.purchases') }}
					</RouterLink>
				</nav>
			</div>

			<div class="mt-8 hidden">
				<div class="border border-warning text-dark py-4 bg-white flex justify-center items-center flex-col rounded-xl">
					<div class="font-semibold">{{ $t('pages.profile.sidebar.referral.title') }}</div>
					<div class="text-sm">{{ $t('pages.profile.sidebar.referral.notion') }}</div>
				</div>
			</div>
		</div>


		<div v-auto-animate class="w-full" v-if="account.profile.email">
			<RouterView v-slot="{ Component }">
				<component :is="Component" />
			</RouterView>
		</div>
  </div>
</template>

<script lang="ts" setup>
import VerifyEmailModal from '@/components/modals/VerifyEmailModal.vue'
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue'

import { useAccountStore } from '../../../stores/account'
import notify from '@/composables/notification'

const account = useAccountStore()

const getRoute = computed(() => {
	return account.router.currentRoute.value.fullPath.split('?')[0]
})

const userNeedsPassword = computed(() => {
	return account.router.currentRoute.value.query.action === 'change-password' && !account.profile.hasPassword
})

const userNeedsEmailVerification = computed(() => {
	return account.router.currentRoute.value.query.action === 'verify-email'
})

const authorizationCode = computed(() => {
	return account.router.currentRoute.value.query.code
})

onMounted(async () => {
	await account.fetchProfile()

})
</script>

<style lang="sass" scoped>
nav
	& > a
		@apply relative duration-100 select-none
		&.active
			@apply text-primary bg-lightblue
		&.muted
			@apply pointer-events-none text-gray
</style>
