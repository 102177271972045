<template>
<div class="w-full" @keydown.enter="(e) => e.preventDefault()">
		<div class="text-left mb-1">
			<label v-if="label" :class="labelLight ? 'gray': 'dark'">{{ label }}</label>
		</div>

		<div class="flex" :class="{ 'w-full': short }">
			<div class="textfield" :class="getTextFieldClasses" @click="focused = true">
				<input :name="label" :autocomplete="getType === 'password' ? 'new-password' : 'off'" :type="getType" :placeholder="placeholder" :value="modelValue" @input.prevent="onInput" @keydown="onKeydown" @focusout="edited = true" ref="input">
				<slot></slot>

				<div class="absolute z-10 right-3 top-1/2 -translate-y-1/2 flex gap-1 items-center">
					<div v-if="type === 'password'">
						<button v-if="!passwordVisible" @click.prevent="showPassword(true)">
							<i class="icon-eye"></i>
						</button>
						<button v-else @click="showPassword(false)">
							<i class="icon-eye-slash"></i>
						</button>
					</div>
					<slot name="append"></slot>
					<div v-if="errors?.length && modified && edited">
						<i class="icon-exclamation-circle text-danger font-semibold text-lg"></i>
					</div>
				</div>
			</div>

			<slot name="append-input"></slot>
		</div>

		<div v-auto-animate="{ duration: 150 }">
			<div class="text-[12px] text-danger font-medium py-1.5" v-if="errors && errors.length > 0 && modified && edited" >{{ errors[0] }}</div>
			<div v-else-if="slots.hint">
				<div class="text-left">
					<span class="text-[12px] text-grayest">
						<slot name="hint"></slot>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
interface Props {
	rounded?: boolean
	type?: 'text' | 'password' | 'number'
	size?: 'sm' | 'md' | 'lg'
	label?: string
	placeholder?: string
	short?: boolean
	modelValue?: string
	disabled?: boolean
	errors?: string[]
	labelLight?: boolean
	number?: boolean
	hint?: string
}

interface Emits {
	(e: 'update:modelValue', value: string): void
}

const [passwordVisible, showPassword] = useToggle()

const slots = useSlots()

const havePassedValidation = computed(() => {
	return props.errors && props.errors.length === 0 && modified.value && edited.value && !!props.modelValue?.length
})

const getType = computed(() => {
	if (!passwordVisible.value && props.type === 'password') return 'password'
	else return props.type
})
const getTextFieldClasses = computed(() => {
	const classes = [props.size, '']
	focused.value ? classes.push('focused') : null
	props.rounded ? classes.push('rounded') : null
	props.disabled ? classes.push('disabled') : null
	props.short === false ? classes.push('w-full') : null

	props.errors && props.errors.length && modified.value ? classes.push('invalid') : null
	havePassedValidation.value ? classes.push('valid') : null

	return classes.join(' ')
})

const props = withDefaults(defineProps<Props>(), {
	size: 'lg',
	short: false
})
const emits = defineEmits<Emits>()

const input = ref()
const modified = ref(false)
const edited = ref(false)
const { focused } = useFocus(input)

function onInput (event: Event) {
	modified.value = true

	const target = event.target as HTMLInputElement

	emits('update:modelValue', target.value)
}

function onKeydown (event: KeyboardEvent) {
	if (props.number) {
		if (/^\D{1}$/g.test(event.key))
			event.preventDefault()
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/variables.scss'

label
	@apply leading-full select-none
	&.dark
		@apply font-medium text-dark text-sm
	&.gray
		@apply text-sm text-gray font-normal

.textfield
	@apply outline outline-1 outline-lightgray px-2 duration-200 relative

	&.disabled
		@apply pointer-events-none opacity-50 select-none

	&.invalid
		@apply outline-2 duration-200
		outline-color: map-get($colors, danger) !important

	&.valid
		@apply outline-2 duration-200
		outline-color: #00B87A !important

	&.rounded
		border-radius: 10px!important
	&.lg
		@apply p-2.5
	&.md
		@apply p-2 px-3
	&.sm
		@apply p-1.5 px-2
	&.focused
		@apply outline-primary outline-2 duration-200
	& > input
		@apply text-dark leading-full w-full bg-transparent font-semibold

		&::placeholder
			@apply font-normal
		&:focus
			@apply outline-none
</style>
