
const config = {
	GURU_VPN_API_BASE_URL: import.meta.env.VITE_API_BACKEND_BASE_URL,
	GOOGLE_AUTH_CLIENT_ID: import.meta.env.VITE_API_GOOGLE_AUTH_CLIENT_ID,
	APPLE_AUTH_CLIENT_ID: import.meta.env.VITE_API_APPLE_AUTH_CLIENT_ID,
	APPLE_AUTH_REDIRECT_URI: import.meta.env.VITE_API_APPLE_REDIRECT_URI,
	APPLE_APP_DOWNLOAD_URL: import.meta.env.VITE_APPLE_APP_DOWNLOAD_URL,
	BASE_URL: ''
}

export default config
