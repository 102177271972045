<template>
	<div class="fixed top-0 left-0 z-50 w-full h-screen flex items-center justify-center" style="background: #00000044">
		<div class="bg-white rounded-xl px-5 py-4 w-1/3 relative" ref="modal">

			<div class="mb-10 text-lg">
				<span v-if="isVerified">
					Email has been successfully verified!
				</span>
				<span v-else>
					Verifying email...
				</span>
			</div>

			<div class="mt-6 flex gap-2">
				<BaseButton theme="green" class="w-full" size="md" @click="account.router.push({ query: {}})"
					:loading="!isVerified">Continue</BaseButton>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useAccountStore } from '../../stores/account'
import notify from '@/composables/notification'

const account = useAccountStore()
const router = useRouter()

const modal = ref()
const [isVerified, setVerified] = useToggle()

async function verifyNewEmail () {
	try {
		await account.verifyEmail(account.router.currentRoute.value.query.code as string)
		await account.fetchProfile()

		notify.success('Email has been successfuly updated')

		setVerified(true)
	} catch (e) {
		notify.error(e as string)
	}
}

onMounted(async () => {
	await verifyNewEmail()
})
</script>

<style lang="sass" scoped>
</style>
