import notify from '@/composables/notification'
import config from '@/config'
import axios, { AxiosError } from 'axios'

import { jwtDecode } from 'jwt-decode'
import { now, useStorage } from '@vueuse/core'

const auth = axios.create({ baseURL: config.GURU_VPN_API_BASE_URL })
const client = axios.create({ baseURL: config.GURU_VPN_API_BASE_URL, withCredentials: true })

const tokens = reactive({
	access: useStorage('auth:access', ''),
	refresh: useStorage('auth:refresh', '')
})

function isTokenExpired (token: string) {
	return token ? jwtDecode(token).exp - (now() / 1000) < 0 : true
}

async function refreshTokens () {
	const { data } = await auth.post('/auth/refresh', { refreshToken: tokens.refresh },
		{ headers: { Authorization: 'Bearer ' + tokens.refresh } }
	)

	tokens.access = data.accessToken
	tokens.refresh = data.refreshToken
}

const isAuthorized = computed(() => {
	return !isTokenExpired(tokens.access) && !isTokenExpired(tokens.refresh)
})

const isAuthenticated = computed(() => {
	return !isTokenExpired(tokens.refresh)
})

async function verifyGoogleAuthCode (code: string) {
	const { data } = await client.post('/auth/oauth/google/verify-token', { code, clientType: 'WEB' })

	tokens.access = data.accessToken
	tokens.refresh = data.refreshToken
}

async function verifyAppleOAuthToken (token: string) {
	const { data } = await client.post('/auth/oauth/apple/verify-token', { token })

	tokens.access = data.accessToken
	tokens.refresh = data.refreshToken
}

async function login (form: LoginForm) {
	const { data } = await client.post('/auth/local/login/email', form)

	tokens.access = data.accessToken
	tokens.refresh = data.refreshToken
}

async function resetPassword (form: ResetPasswordForm) {
	await client.post('/auth/reset-password', form)
}

async function confirmResetPassword (form: ConfirmResetPasswordForm) {
	await client.post('/auth/reset-password/confirm', form)
}

async function verifyEmailChange (form: VerifyEmailChangeForm) {
	await client.post('/auth/change-email/confirm', form)
}

async function verifyAccount (form: VerifyAccountForm) {
	await client.post('/auth/demo-account/activate/email/verify-code', form)
}

async function register (form: RegisterForm) {
	await client.post('/auth/local/register/email', form)
}

async function logout () {
	tokens.access = ''
	tokens.refresh = ''
}

client.interceptors.request.use(async config => {
	if (isTokenExpired(tokens.access) && !isTokenExpired(tokens.refresh))
		await refreshTokens()

	config.headers.Authorization = `Bearer ${tokens.access}`

	return config
}, error => {
	return Promise.reject(error)
})


client.interceptors.response.use(r => r, async (error: AxiosError) => {
	const details = error.response.data as { message: string }
	notify.error(details.message || error.message)

	if (error.response.status === 401)
		await logout()

	return Promise.reject(error)
})

export default { client, login, logout, register, isAuthenticated, isAuthorized, verifyAppleOAuthToken, verifyGoogleAuthCode, resetPassword, confirmResetPassword, verifyEmailChange, verifyAccount }
