<template>
	<div v-auto-animate>
		<div class="w-full">
			<BaseCard :title="$t('pages.profile.purchases.title')" slim>
				<template #header>
					<i v-if="account.loading.subscriptions" class="icon-circle-spinner animate-spin"></i>
				</template>

				<section class="p-8 pb-6">
					<div v-if="activeSubscriptions.length">
						{{ $t('common.your') }} <strong>Premium</strong> {{ $t('pages.profile.purchases.status.active') }} <strong>{{ account.getSubscriptionExpirationDate }}</strong>
					</div>

					<div :class="{'pt-6': activeSubscriptions.length}">
						<div class="flex items-center gap-2 text-dark">
							<i class="icon-shield-check text-2xl"></i>

							<span class="text-lg font-semibold">{{ $t('pages.profile.purchases.subscriptions.title') }}</span>
						</div>
					</div>

					<div v-if="!account.loading.subscriptions">
						<div v-if="activeSubscriptions.length" class="pt-6">
							<div v-auto-animate>
								<UserActiveSubscriptionsTable v-if="account.subscriptions.length" active :items="account.subscriptions"/>
							</div>
						</div>
						<div v-else class="pt-6">
							<div class="mb-4 text-gray text-sm">
								{{  $t('pages.profile.purchases.subscriptions.empty') }} <br/>
							</div>
							<RouterLink to="/account/plans" class="text-sm font-semibold underline text-primary mr-4">
								Manage subscription
							</RouterLink>
						</div>
					</div>
				</section>

				<div class="h-1 bg-lightblue"></div>

				<section class="p-8 pt-2">
					<div>
						<div class="py-6">
							<div class="flex items-center gap-2 text-dark">
								<i class="icon-payment-card text-2xl"></i>
								<span class="text-lg font-semibold">{{ $t('pages.profile.purchases.purchases.title') }}</span>
							</div>
						</div>
						<div v-auto-animate>
							<UserPurchaseHistoryTable v-if="account.subscriptions.length" :items="account.subscriptions"/>
						</div>
					</div>
				</section>
			</BaseCard>

			<BaseModal :open="!!getSelectedSubscription" title="Manage subscription" @close="account.router.replace('/account/profile/purchases')">
				<div class="w-full flex border-b-2 border-lightblue py-3 child:w-1/2">
					<div class="pl-2 font-semibold">{{ $t('pages.profile.purchases.purchases.table.head.order') }}</div>
					<div>{{ getSelectedSubscription.id }}</div>
				</div>
				<div class="w-full flex border-b-2 border-lightblue py-3 child:w-1/2">
					<div class="pl-2 font-semibold">{{ $t('pages.profile.purchases.purchases.table.head.package') }}</div>
					<div>Premium {{ getSelectedSubscription.name }}</div>
				</div>
				<div class="w-full flex border-b-2 border-lightblue py-3 child:w-1/2">
					<div class="pl-2 font-semibold">{{ $t('pages.profile.purchases.purchases.table.head.provider') }}</div>
					<div>Cleverbridge</div>
				</div>
				<div class="w-full flex border-b-2 border-lightblue py-3 child:w-1/2">
					<div class="pl-2 font-semibold">{{ $t('modals.subscription.manage.labels.payment') }}</div>
					<div v-if="getSelectedSubscription.autorenew">{{ new Date(getSelectedSubscription.expiresAt).toDateString() }}</div>
					<div v-else class="text-grayest">Not available</div>
				</div>
				<div class="w-full flex border-b-2 border-lightblue py-3 child:w-1/2">
					<div class="pl-2 font-semibold">{{ $t('modals.subscription.manage.labels.amount') }}</div>
					<div v-if="getSelectedSubscription.autorenew">${{ getSelectedSubscription.price }}</div>
					<div v-else class="text-grayest">$0</div>
				</div>
				<div class="w-full flex py-3 child:w-1/2">
					<div class="pl-3">
					</div>
					<div class="font-medium text-primary" v-auto-animate="{ duration: 100 }">
						<button v-if="!isCancelMode && getSelectedSubscription.autorenew" class="underline" @click="toggleCancelMode(true)">
							{{ $t('modals.subscription.manage.actions.cancel') }}
						</button>
						<button class="text-danger" v-else-if="!getSelectedSubscription.autorenew">
							Cancelled
						</button>
						<div v-else class="flex gap-2">
							<BaseButton theme="danger" size="md" class="w-full" variant="outlined" :loading="account.loading.update.subscription"
								@click="account.cancelPersonalSubscription(getSelectedSubscription.uuid)"
							>
								Yes, cancel
							</BaseButton>
						</div>
					</div>
				</div>

			</BaseModal>
		</div>
	</div>
</template>

<script lang="ts" setup>
import UserActiveSubscriptionsTable from '@/components/dashboard/purchases/UserActiveSubscriptionsTable.vue'
import UserPurchaseHistoryTable from '@/components/dashboard/purchases/UserPurchaseHistoryTable.vue'

import { useAccountStore } from '@/stores/account'

const account = useAccountStore()
const manageId = computed(() => {
	return Number(account.router.currentRoute.value.query.manage)
})

const [isCancelMode, toggleCancelMode] = useToggle()

const getSelectedSubscription = computed(() => {
	return account.subscriptions.find(x => x.id === manageId.value)
})

const activeSubscriptions = computed(() => {
	return (account.subscriptions ?? []).filter(subscription => new Date(subscription.expiresAt).getTime() > Date.now())
})

onMounted(async () => {
	await account.fetchUserSubscriptions()

	if (!account.profile.email)
		await account.fetchProfile()
})
</script>

<style lang="sass" scoped>
</style>
