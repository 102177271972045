<template>
	<div class="fixed top-0 left-0 z-50 w-full h-screen flex items-center justify-center" style="background: #00000044">
		<div class="bg-white rounded-xl px-5 py-4 w-1/3 relative" ref="modal">

			<div class="mb-4 text-lg">
				Complete your registration
			</div>

			<div class="flex flex-col gap-8">
				<TextField rounded label-light label="New password" type="password" v-model="form.password" :errors="validation.errors.password" />
			</div>

			<div class="mt-6 flex gap-2">
				<BaseButton class="w-full" size="lg" @click="createNewPassword" :disabled="!validation.valid" :loading="account.loading.update.password">Confirm</BaseButton>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import patterns from '@/composables/validation/patterns'

import { z } from 'zod'
import { useRouter } from 'vue-router'
import { useAccountStore } from '../../stores/account'
import notify from '@/composables/notification'

const account = useAccountStore()
const router = useRouter()

const modal = ref()

const form = reactive({
	password: ''
})

onClickOutside(modal, () => {
	router.push({ query: { action: undefined, code: undefined } })
})

const PasswordForm = z.object({
	password: z.string().regex(patterns.auth.password, { message: 'Incorrect password' }).default('')
}).required()

const validation = computed(() => {
	const check = PasswordForm.safeParse(form)
	if (check.success === false) {
		return {
			valid: false,
			errors: check.error.formErrors.fieldErrors
		}
	}
	else {
		return {
			valid: true,
			errors: {}
		}
	}
})

async function createNewPassword () {
	try {
		await account.verifyPasswordReset(form.password, account.router.currentRoute.value.query.code as string)

		notify.success('Password has been successfuly updated')

		account.router.push({ query: {} })
	} catch (e) {
		notify.error(e as string)
	}
}
</script>

<style lang="sass" scoped>
</style>
