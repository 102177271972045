
<template>
	<section v-auto-animate>
		<div v-if="app.loading.state" class="w-full fixed z-90 left-0 top-0 h-screen bg-white flex items-center justify-center">
			<svg class="stroke-primary" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" stroke-linecap="round"><animate attributeName="stroke-dasharray" dur="1.5s" calcMode="spline" values="0 150;42 150;42 150;42 150" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/><animate attributeName="stroke-dashoffset" dur="1.5s" calcMode="spline" values="0;-16;-59;-59" keyTimes="0;0.475;0.95;1" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" repeatCount="indefinite"/></circle><animateTransform attributeName="transform" type="rotate" dur="2s" values="0 12 12;360 12 12" repeatCount="indefinite"/></g></svg>
		</div>
	</section>

  <notifications position="bottom right" />
  <RouterView />
</template>

<script lang="ts" setup>
import api from '@/composables/api'

import i18next from 'i18next'

import { useAppStore } from './stores/app'

const app = useAppStore()

watch(api.isAuthenticated, async (authorized) => {
	if (!authorized)
		await app.router.replace('/account/login?action=logout')
})

onBeforeMount(async () => {
	await i18next.changeLanguage(app.settings.language)
})
</script>

<style lang="sass">
body
  background: #F7F9FC
</style>
