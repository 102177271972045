import i18next from 'i18next'

import en from './locales/en.json'
import de from './locales/de.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import ru from './locales/ru.json'

i18next.init({
	lng: 'en',
	resources: {
		en: { translation: en },
		ru: { translation: ru },
		es: { translation: es },
		de: { translation: de },
		fr: { translation: fr }
	}
})
