<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="255" height="192" viewBox="0 0 255 192" fill="none">
  <g clip-path="url(#clip0_194_687)">
    <path d="M239.157 112.1C235.793 135.954 225.198 157.006 209.9 173.354C208.633 174.709 207.451 171.958 206.12 173.247C181.977 196.633 147.68 209.032 111.818 203.973C104.711 202.971 97.8513 201.326 91.2915 199.107C89.9298 198.646 88.4636 202.232 87.1279 201.723C39.0569 183.375 8.26469 133.697 15.7554 80.5893C24.4569 18.8986 81.5209 -24.0577 143.212 -15.3562C204.902 -6.65482 247.859 50.4092 239.157 112.1Z" fill="#4875D1"/>
    <path d="M231.035 110.181C223.603 162.875 174.861 199.568 122.166 192.135C69.472 184.703 32.7808 135.96 40.2132 83.2661C47.6457 30.572 96.3878 -6.12008 149.082 1.31237C201.776 8.74482 238.468 57.4869 231.035 110.181Z" fill="#7093DB"/>
    <path d="M148.037 86.7744L79.6991 156.351C78.6604 157.408 76.8955 157.159 76.1897 155.856L29.763 70.092C28.902 68.5015 30.2174 66.6089 32.0081 66.8614L47.795 69.0882C48.9997 69.2581 50.1145 68.4189 50.2844 67.2142L63.2087 -24.4154C63.3786 -25.6202 64.4934 -26.4593 65.6981 -26.2894L140.162 -15.7862C141.367 -15.6163 142.206 -14.5016 142.036 -13.2969L129.112 78.3328C128.942 79.5375 129.781 80.6523 130.986 80.8222L146.773 83.0489C148.563 83.3014 149.304 85.4842 148.037 86.7744Z" fill="#F7D06E"/>
    <path d="M87.6605 148.245L79.6991 156.351C78.6604 157.408 76.8955 157.159 76.1897 155.856L29.763 70.092C28.902 68.5015 30.2174 66.6089 32.0081 66.8614L47.795 69.0882C48.9997 69.2581 50.1145 68.4189 50.2844 67.2142L63.2087 -24.4154C63.3786 -25.6202 64.4934 -26.4593 65.6981 -26.2894L77.1356 -24.6761L62.3557 80.1094C62.1858 81.3141 61.0711 82.1533 59.8663 81.9833L55.4472 81.36C53.6565 81.1074 52.3411 83.0001 53.2021 84.5905L87.6605 148.245Z" fill="#F5C03D"/>
    <path d="M221.27 69.0808L206.696 172.412C206.235 175.674 204.645 178.679 202.176 180.86C192.15 189.715 180.584 196.774 168.02 201.628C167.528 201.818 165.99 200.722 165.494 200.906C163.952 201.477 162.395 202.015 160.825 202.519C160.039 202.771 160.292 204.299 159.499 204.534C144.462 208.997 128.248 210.379 111.701 208.046C110.977 207.943 110.255 207.834 109.537 207.719C98.7028 205.98 91.2984 195.817 92.8309 184.952L111.361 53.5782C111.871 49.9636 115.215 47.4465 118.829 47.9564L215.649 61.6127C219.263 62.1225 221.78 65.4662 221.27 69.0808Z" fill="#DEECF1"/>
    <path d="M139.04 50.807C135.425 50.2972 132.081 52.8143 131.571 56.4288L110.217 207.826C104.389 206.927 98.7327 205.595 93.2754 203.867C91.5514 203.321 90.483 201.598 90.7355 199.807L111.361 53.5782C111.871 49.9636 115.215 47.4465 118.829 47.9564L139.04 50.807Z" fill="#A8D3D8"/>
    <path d="M201.695 76.11L194.644 75.1155C192.836 74.8606 191.578 73.1887 191.833 71.3814C192.088 69.5741 193.759 68.3156 195.567 68.5705L202.618 69.5651C204.425 69.82 205.684 71.4918 205.429 73.2991C205.174 75.1064 203.502 76.3649 201.695 76.11ZM132.768 63.0504C133.023 61.2431 131.764 59.5712 129.957 59.3163L129.151 59.2027C127.344 58.9478 125.672 60.2063 125.417 62.0136C125.162 63.8209 126.421 65.4927 128.228 65.7476L129.034 65.8613C130.841 66.1162 132.513 64.8576 132.768 63.0504ZM144.354 64.6845C144.609 62.8772 143.35 61.2054 141.543 60.9505L140.737 60.8369C138.93 60.5819 137.258 61.8405 137.003 63.6478C136.748 65.455 138.007 67.1269 139.814 67.3818L140.62 67.4954C142.427 67.7504 144.099 66.4918 144.354 64.6845ZM165.19 198.661L129.411 193.614C127.604 193.359 125.932 194.618 125.677 196.425C125.422 198.233 126.681 199.904 128.488 200.159L159.499 204.533C162.385 203.677 165.227 202.707 168.02 201.628C167.883 200.136 166.738 198.879 165.19 198.661ZM181.317 194.26C183.124 194.515 184.796 193.257 185.051 191.45C185.305 189.642 184.047 187.97 182.24 187.715L116.054 178.38C114.247 178.125 112.575 179.384 112.32 181.191C112.065 182.998 113.324 184.67 115.131 184.925L181.317 194.26Z" fill="#8EBAC5"/>
    <path d="M175.241 69.0411C174.986 70.8488 173.315 72.107 171.507 71.852L158.417 70.0057C156.609 69.7507 155.351 68.0793 155.606 66.2716C155.861 64.4639 157.532 63.2057 159.34 63.4607L172.43 65.307C174.237 65.5619 175.496 67.2334 175.241 69.0411ZM222.238 92.358C224.046 92.613 225.718 91.3548 225.973 89.5471L227.819 76.4572C228.074 74.6494 226.816 72.9781 225.008 72.7231C224.697 72.6793 224.391 72.6801 224.094 72.721L224.543 69.5424C225.306 64.1292 221.523 59.1037 216.11 58.3402L119.29 44.6839C113.877 43.9203 108.852 47.7034 108.088 53.1166L107.64 56.2948C107.367 56.174 107.072 56.0884 106.762 56.0445C104.954 55.7896 103.283 57.0477 103.028 58.8555L101.181 71.9454C100.926 73.7531 102.184 75.4245 103.992 75.6795C104.303 75.7233 104.609 75.7224 104.905 75.6819L87.1275 201.722C88.4632 202.232 89.812 202.718 91.1737 203.179C91.9421 203.439 92.7142 203.691 93.4906 203.935L114.633 54.0397C114.888 52.2351 116.563 50.9743 118.367 51.2288L215.187 64.8851C216.991 65.1397 218.252 66.8145 217.998 68.6192L202.165 180.869C204.858 178.492 207.44 175.984 209.9 173.355L217.606 118.723C217.879 118.844 218.174 118.93 218.484 118.974C220.292 119.229 221.963 117.971 222.218 116.163L224.065 103.073C224.32 101.266 223.061 99.5943 221.254 99.3393C220.943 99.2955 220.636 99.2963 220.34 99.3373L221.36 92.1077C221.633 92.2286 221.927 92.3141 222.238 92.358Z" fill="#384949"/>
    <path d="M187.879 156.205C187.466 156.658 186.971 157.031 186.421 157.302C179.077 160.928 170.593 162.442 161.868 161.168C139.246 157.865 123.357 136.758 126.44 114.105C127.625 105.402 131.401 97.6939 136.883 91.65C137.338 91.1481 137.883 90.7381 138.491 90.4394C145.815 86.8439 154.267 85.3468 162.959 86.6159C185.58 89.9188 201.469 111.026 198.386 133.679C197.196 142.416 193.395 150.15 187.879 156.205Z" fill="#4BCD5C"/>
    <path d="M188.087 155.975C179.083 166.006 165.409 171.513 151.059 169.489C128.166 166.26 112.225 145.084 115.454 122.191C117.478 107.842 126.554 96.2239 138.683 90.3459C133.322 96.3179 129.618 103.893 128.412 112.437C125.183 135.33 141.124 156.506 164.017 159.735C172.561 160.94 180.865 159.475 188.087 155.975Z" fill="#00C53B"/>
    <path d="M196.584 104.973L152.621 147.724C151.888 148.436 150.867 148.769 149.855 148.627C148.843 148.484 147.954 147.881 147.448 146.994L132.571 120.958C131.665 119.374 132.216 117.355 133.801 116.449C135.385 115.544 137.404 116.094 138.309 117.679L151.07 140.011L191.976 100.234C193.285 98.9616 195.377 98.9901 196.649 100.299C197.922 101.609 197.892 103.701 196.584 104.973ZM180.931 39.6109C182.029 41.0692 181.737 43.1413 180.278 44.2386C179.549 44.7873 178.667 44.989 177.829 44.8709C176.992 44.7528 176.199 44.3152 175.651 43.5859C172.478 39.3714 166.468 38.5231 162.252 41.696C160.794 42.7938 158.722 42.5015 157.624 41.0433C156.527 39.585 156.819 37.5129 158.277 36.4156C165.404 31.0509 175.566 32.4838 180.931 39.6109ZM193.425 30.6661C182.844 16.6106 162.801 13.7835 148.745 24.3641C147.287 25.4619 146.995 27.534 148.093 28.9918C148.641 29.7212 149.434 30.1587 150.271 30.2768C151.109 30.3949 151.991 30.1937 152.72 29.6446C163.864 21.2561 179.755 23.4976 188.144 34.6411C189.242 36.0994 191.314 36.3917 192.772 35.2938C194.23 34.1965 194.523 32.1244 193.425 30.6661Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_194_687">
      <rect width="225.613" height="225.613" fill="white" transform="translate(31.5107 -31.1116) rotate(8.02856)"/>
    </clipPath>
  </defs>
</svg>
</template>
