const patterns = {
	integer: {
		positive: /[1-9]+[0-9]*/g,
		negative: /-[1-9]+[0-9]*/g,
		any: /-?[1-9]+[0-9]*/g
	},
	auth: {
		email: /[\w-.]+@([\w-]+\.)+[\w-]{2,4}/g,
		// password: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,32}/g,
		password: /(?=.*[A-z]).{6,32}/g,
		username: /[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){2,18}[a-zA-Z0-9]/g
	},

	hexAddress: /(0[xX][0-9a-fA-F]{40})/g,
	hexadecimal: /([a-f0-9])+/g
}


export default patterns
